@import '../../styles/customMediaQueries.css';

.root {}

.verificiationBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 15px 32px 23px 32px;
  margin-bottom: 24px;

  border-radius: 4px;
}

@media (--viewportMedium) {
  .verificiationBox {
    flex-wrap: nowrap;
  }
}

.verificiationBoxTextWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 24px 3px 0px;
  margin-bottom: 16px;
}

@media (--viewportMedium) {
  .verificiationBoxTextWrapper {
    margin-bottom: 0px;
  }
}

.verificationBoxTitle {
  composes: h4 from global;
  font-weight: var(--fontWeightSemiBold);

  align-self: center;

  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.verificationBoxText {
  composes: h4 from global;
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;
}

.getVerifiedButton {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);

  border-radius: 4px;
  min-height: 38px;
  width: 300px;
  max-width: 110px;
}

/* Verification required box */
.verficiationNeededBox {
  border: 1px solid var(--attentionColor);
  background: var(--attentionColorLight);
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--successColor);
  stroke-width: 3px;
}

.dynamicSelect,
.dateSection,
.selectCurrency,
.dynamicText {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
}

.monthElement {
  display: flex;
  justify-content: center;
  align-items: center;
}

.monthElement select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
  text-indent: 1px;
  text-overflow: '';
  border: 0px solid transparent;

  &:hover {
    border: 0px solid transparent;
  }
}

.fetchRequirements {
  margin: 12px 0;
  color: var(--colorGrey700);
  font-size: 14px;
}
